<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="Timedate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeDate"
        value-format="yyyy-MM-dd"
        class="filter-item"
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getCollegeList(),
            (listQuery.school_year = null),
            (listQuery.college_id = null),
            (listQuery.major_id = null),
            (listQuery.semester = null),
            (listQuery.grade_id = null),
            (listQuery.class_id = null)
        "
      >
        <el-option
          v-for="item in schools"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        @change="
          getGradeList(),
            (listQuery.college_id = null),
            (listQuery.major_id = null),
            (listQuery.semester = null),
            (listQuery.grade_id = null),
            (listQuery.class_id = null)
        "
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getMajorList(),
            (listQuery.major_id = null),
            (listQuery.semester = null),
            (listQuery.grade_id = null),
            (listQuery.class_id = null)
        "
      >
        <el-option
          v-for="item in colleges"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in majors"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="getClassList(), (listQuery.class_id = null)"
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" @click="getList"
        >查找</el-button
      >
    </div>

    <!-- <div class="title">学校 院系 专业 学年 第x学期 x年纪 x班</div> -->
    <div class="title tip_box">
      未提交
      <div class="tip color_1"></div>
      已提交/待审核
      <div class="tip color_2"></div>
      已通过
      <div class="tip color_3"></div>
      已驳回
      <div class="tip color_4"></div>
      驳回次数:{{ reject_num }}
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @sort-change="changeSort"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.classes ? scope.row.classes.college.school.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.classes ? scope.row.classes.college.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.classes ? scope.row.classes.major.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="年度" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.classes
              ? scope.row.classes.school_year +
                "~" +
                (scope.row.classes.school_year - 0 + 1)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes ? scope.row.classes.grade.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes ? scope.row.classes.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_course ? scope.row.school_course.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="上课教师" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher ? scope.row.teacher.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="教学设计" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].teaching_plan
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].teaching_plan[0]
                        .examine_status
                    ]
              "
            >
              授课计划
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].course_standard
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].course_standard[0]
                        .examine_status
                    ]
              "
            >
              课程标准
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].course_outline
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].course_outline[0]
                        .examine_status
                    ]
              "
            >
              课程大纲
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].teaching_calendar
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].teaching_calendar[0]
                        .examine_status
                    ]
              "
            >
              教学日历
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].lecture
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].lecture[0].examine_status
                    ]
              "
            >
              说课
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="课件资料" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].handout
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].handout[0].examine_status
                    ]
              "
            >
              讲义
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].handout ||
                  scope.row.class_course_data[2].handout[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].lesson_plan
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].lesson_plan[0]
                        .examine_status
                    ]
              "
            >
              教案
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].lesson_plan ||
                  scope.row.class_course_data[2].lesson_plan[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].video_audio
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].video_audio[0]
                        .examine_status
                    ]
              "
            >
              音频/视频
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].video_audio ||
                  scope.row.class_course_data[2].video_audio[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="学生考试" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].excellent_homework
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].excellent_homework[0]
                        .examine_status
                    ]
              "
            >
              作业
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].excellent_answer
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].excellent_answer[0]
                        .examine_status
                    ]
              "
            >
              试卷
            </div>
          </div>
          <!-- <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].school_report
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].school_report[0]
                        .examine_status
                    ]
              "
            >
              成绩表
            </div>
          </div> -->
        </template>
      </el-table-column>
      <el-table-column label="学生反馈" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[4] ||
                !scope.row.class_course_data[4].teaching_quality_evaluation_form
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[4]
                        .teaching_quality_evaluation_form[0].examine_status
                    ]
              "
            >
              全度教学质量评价表
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="教师总结" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[5] ||
                !scope.row.class_course_data[5].teacher_summary
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[5].teacher_summary[0]
                        .examine_status
                    ]
              "
            >
              教师总结
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="教学评定" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[6] ||
                !scope.row.class_course_data[6]
                  .teaching_quality_evaluation_form_expert
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[6]
                        .teaching_quality_evaluation_form_expert[0]
                        .examine_status
                    ]
              "
            >
              全度教学质量评价表(专家专用)
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[6] ||
                !scope.row.class_course_data[6]
                  .teaching_quality_evaluation_form_teacher
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[6]
                        .teaching_quality_evaluation_form_teacher[0]
                        .examine_status
                    ]
              "
            >
              全度教学质量评价表(教师专用)
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="查课评价" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].student_evaluation_feedback_form
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7]
                        .student_evaluation_feedback_form[0].examine_status
                    ]
              "
            >
              全度教学巡课-学生评价反馈表
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].class_record
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7].class_record[0]
                        .examine_status
                    ]
              "
            >
              全度教学巡课-课堂情况记录表
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].total_score_of_class_tour
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7]
                        .total_score_of_class_tour[0].examine_status
                    ]
              "
            >
              全度教学巡课-巡课评分总表
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleClassSchedule(scope.row)"
            >教学设计</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleCoursewareInfo(scope.row)"
            >课件资料</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handlerStudentExam(scope.row)"
            >学生考试</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleStudentScore(scope.row)"
            >学生成绩</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleTeacherSummary(scope.row)"
            >教师总结</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleDownload(scope.row)"
          >
            一键下载资料
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
// import JSZip from "jszip";
import axios from "axios";
// import fileSaver from "file-saver";
import streamsaver from "streamsaver";
import ZipStream from "@/utils/zip-stream";
const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        school_id: null,
        college_id: null,
        major_id: null,
        school_year: null,
        semester: null,
        grade_id: null,
        class_id: null,
        school_name_sort: "",
        college_name_sort: "",
        major_name_sort: "",
        start_at: "",
        end_at: "",
      },
      list: [],
      total: null,
      listLoading: false,
      Timedate: null,
      reject_num: null,
      form: {
        id: "",
      },
      schools: [],
      colleges: [],
      majors: [],
      grades: [],
      classes: [],
      DownloadList: new Map(),
      color: ["", "color_2", "color_3", "color_4"],
      data: {},
    };
  },
  created() {
    this.getList();
    this.getSchoolList();
  },
  methods: {
    forKey(row, text) {
      for (const key in row) {
        let li = row[key];
        li[0].data.forEach((item) => {
          let count=1,fileName=text + "/",ext=''

          let itemChunk=item.name.split('.')
          ext=itemChunk[itemChunk.length-1]
          itemChunk.splice(itemChunk.length-1,1)
          fileName+=itemChunk.join('.')

          if(this.DownloadList.has(fileName+'.'+ext)){
            while(this.DownloadList.has(fileName+count+'.'+ext))count++;
            this.DownloadList.set(fileName+count+'.'+ext,{
              url: item.url,
              name: fileName+count+'.'+ext,
            })
          }else{
            this.DownloadList.set(fileName+'.'+ext,{
              url: item.url,
              name: fileName+'.'+ext,
            });
          }
        });
      }
    },
    zipFiles(zipName, files) {
      console.log('压缩文件？')
      // // 创建压缩文件输出流
      const zipFileOutputStream = streamsaver.createWriteStream(zipName);
      // // 创建下载文件流
      const fileIterator = files.values();
      const readableZipStream = new ZipStream({
        async pull(ctrl) {
          const fileInfo = fileIterator.next();
          if (fileInfo.done) {
            //迭代终止
            console.log('走到这里')
            ctrl.close();
          } else {
            const { name, url } = fileInfo.value;
            return fetch(url).then((res) => {
              ctrl.enqueue({
                name,
                stream: () => res.body,
              });
            });
          }
        },
      });
      if (window.WritableStream && readableZipStream.pipeTo) {
        // 开始下载
        console.log('下载开始')
        readableZipStream
          .pipeTo(zipFileOutputStream)
          .then(() => {
            console.log("同步下载打包结束时间：" + new Date())
            this.DownloadList.clear();
          }).catch(err=>{console.log('获取到错误',err)})
      }
    },
    handleDownload(row) {
      // this.listLoading = true;
       this.$notify({
        title: "提示",
        type: 'warning',
        message: "刷新页面会导致下载文件破损",
        duration: 0,
      });
      this.dataup(row);
      const data = [];
      for (const key in row.class_course_data) {
        console.log(key);
        let item = row.class_course_data[key];
        if (key == 1) {
          this.forKey(item, "教学计划");
        } else if (key == 2) {
          this.forKey(item, "课件资料");
        } else if (key == 3) {
          this.forKey(item, "学生考试");
        } else if (key == 4) {
          this.forKey(item, "学生反馈");
        } else if (key == 5) {
          this.forKey(item, "教师总结");
        } else if (key == 6) {
          this.forKey(item, "教学评定");
        } else if (key == 7) {
          this.forKey(item, "查课评价");
        }
      }
      this.DownloadList.forEach((item) => {
        if (item.url) {
          let routeUrl = item.url.replace(/http:/, "https:");
          data.push({ url: routeUrl, name: item.name });
        }
      });
      console.log('下载文件数组',this.DownloadList)
      let zipName = `${this.data.school_name}${this.data.college_name}${
        this.data.major_name
      }${this.data.school_year}-${this.data.school_year - 0 + 1}年${
        this.data.classes_name
      }--${row.school_course.name} 教务资料.zip`;
      this.zipFiles(zipName, data);

    },
    changeDate() {
      if (!this.Timedate || this.Timedate.length == 0) {
        return;
      }
      this.listQuery.start_at = this.Timedate[0];
      this.listQuery.end_at = this.Timedate[1];
    },
    //排序
    changeSort(e) {
      console.log(e);
      this.listQuery.school_name_sort = "";
      this.listQuery.college_name_sort = "";
      this.listQuery.major_name_sort = "";
      if (e.order == null) {
        this.getList();
        return;
      }
      switch (e.column.label) {
        case "学校":
          if (e.order == "ascending") {
            this.listQuery.school_name_sort = 0;
          } else {
            this.listQuery.school_name_sort = 1;
          }
          break;
        case "院系":
          if (e.order == "ascending") {
            this.listQuery.college_name_sort = 0;
          } else {
            this.listQuery.college_name_sort = 1;
          }
          break;
        case "专业":
          if (e.order == "ascending") {
            this.listQuery.major_name_sort = 0;
          } else {
            this.listQuery.major_name_sort = 1;
          }
          break;
        default:
          break;
      }
      this.getList();
    },
    dataup(row) {
      this.data.school_name = row.classes.college.school.name; //学校
      this.data.college_name = row.classes.college.name; //院系
      this.data.semester = row.semester; //学期
      this.data.school_year = row.classes.school_year; //学年
      this.data.grade_name = row.classes.grade.name; //年级
      this.data.major_name = row.classes.major.name; //专业
      this.data.classes_name = row.classes.name; //班级
      this.data.teacher = row.teacher.name; //教师
      this.data.major_name = row.classes.major.name; //教师
      localStorage.setItem("classdata", JSON.stringify(this.data));
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    getCollegeList() {
      request({
        url: "/api/teacherend/school/collegeList",
        method: "get",
        params: {
          limit: 99999999999999999,
          school_id: this.listQuery.school_id,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getMajorList() {
      request({
        url: "/api/teacherend/school/majorList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
        },
      }).then((response) => {
        this.majors = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/teacherend/school/gradeList",
        method: "get",
        params: {
          limit: 99999999999999999,
          school_id: this.listQuery.school_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      request({
        url: "/api/teacherend/school/classList",
        method: "get",
        params: {
          limit: 99999999999999999,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          grade_id: this.listQuery.grade_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
        this.$forceUpdate(); //强制更新
      });
    },
    handleClassSchedule(row) {
      this.dataup(row);
      this.$router.push(
        `/task/instructionaldesign?type=1&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleTeacherSummary(row) {
      this.dataup(row);
      this.$router.push(
        `/task/teachersummary?type=5&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleCoursewareInfo(row) {
      this.dataup(row);
      this.$router.push(
        `/task/coursewareinfo?type=2&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleStudentScore(row) {
      this.dataup(row);
      this.$router.push(
        `/task/studentscore?class_id=${row.class_id}&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handlerStudentExam(row) {
      this.dataup(row);
      this.$router.push(
        `/task/studentexam?type=3&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/eduAdmin/courseList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.reject_num = response.data.reject_num;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
.tip_box {
  display: flex;
  align-items: center;
  .tip {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 5px;
  }
  .color_1 {
    background: black;
  }
  .color_2 {
    background: #11eeee;
  }
  .color_3 {
    background: #4db34d;
  }
  .color_4 {
    background: red;
  }
}
.tip {
  width: 20px;
  height: 20px;
  margin: 0 20px 0 5px;
}

.table_box {
  display: flex;
  justify-content: center;
  .color_1 {
    color: black;
  }
  .color_2 {
    color: #11eeee;
  }
  .color_3 {
    color: #4db34d;
  }
  .color_4 {
    color: red;
  }
}
</style>
